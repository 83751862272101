import { APIClient } from './API';

export interface User {
  id: string;
  name: string;
  userName: string;
  normalizedUserName: string;
  email: string;
  normalizedEmail: string;
  emailConfirmed: boolean;
  firstName: string;
  createdAt: Date;
  lastLoginAt: Date;
  userSubscriptionStatus: string;
  status: string;
  hubSpotCompanyId: string;
}

interface UserDTO {
  id: string;
  name: string;
  userName: string;
  normalizedUserName: string;
  email: string;
  normalizedEmail: string;
  emailConfirmed: boolean;
  firstName: string;
  createdAt: string;
  lastLoginAt: string;
  userSubscriptionStatus: string;
  status: string;
  hubSpotCompanyId: string;
}

export const signUpUser = async (userId: string, password: string, confirmPassword: string): Promise<void> => {
  await APIClient.Post('users/sign-up', { userId, password, confirmPassword });
};

export const getUserEmail = async (userId: string): Promise<string> => {
  const user = await APIClient.Get<UserDTO>(`users/${userId}/invited-email`);
  return user.email;
};

export const resetUserPassword = async (code: string, email: string, password: string, confirmPassword: string): Promise<void> => {
  return APIClient.Put('auth/reset-password', { code, email, password, confirmPassword });
};

export interface Notification {
  id: number;
  userId: string;
  mainText: string;
  readMoreLinkUrl?: string;
  isRead: boolean;
  createdAt?: Date;
}

export interface NotificationDTO {
  id: number;
  userId: string;
  mainText: string;
  isRead: boolean;
  readMoreLinkUrl?: string;
  createdAt?: string;
}

export const getUserNotifications = async (token: string): Promise<Notification[]> => {
  const { notifications: notificationsResponse } = await APIClient.AuthorizedGet<{
    notifications: NotificationDTO[];
  }>(token, `user/notifications`);

  return notificationsResponse.map(notification => ({
    ...notification,
    createdAt: notification.createdAt ? new Date(notification.createdAt) : undefined,
  }));
};

export const markNotificationAsRead = async (token: string, notificationId: number): Promise<void> => {
  await APIClient.AuthorizedPut(token, `user/notifications/${notificationId}/read`, {});
};

export const markAllNotificationsAsRead = async (token: string): Promise<void> => {
  await APIClient.AuthorizedPut(token, `user/notifications/read-all`, {});
};

export interface OpenApiSettings {
  apiKey: string;
}

export const getOpenApiSettings = async (token: string): Promise<OpenApiSettings> => {
  return APIClient.AuthorizedGet<OpenApiSettings>(token, `user/open-api-settings`);
};
