import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FormCheck } from 'react-bootstrap';

export const blue1 = '#F7FAFF';
export const gray1 = '#747474';
export const gray2 = '#F2F2F2';
export const gray3 = '#ACAAAA';
export const gray4 = '#F9F6F1';
export const gray5 = '#F1EBE0';
export const gray6 = '#f7f8fa';
export const gray7 = '#5C5C5C';
export const gray8 = '#E9E9EB';
export const gray9 = '#F6F7FB';
export const primary1 = '#262024';
export const primary2 = '#070D19';
export const primary3 = '#010A1B';
export const green1 = '#06C270';
export const link = '#262024';
export const checkbox = '#1C4AB9';
export const accent1 = '#dbb365';
export const accent2 = '#efd191';
export const dangerColor = '#ef5350';
export const errorColor = '#FF3B3B';
export const errorColor2 = '#E96166';
export const errorBackgroundColor2 = '#E96166';
export const badgeActiveColor = '#F1F6FE';
export const badgeInactiveColor = gray4;
export const simproYellowTextColor = '#FEB100';
export const simproYellowHoverLinkColor = '#c79902';
export const fontFamily1 = "'Circular Std Book', 'Helvetica Neue', 'sans serif'";
export const fontFamily2 = "'Circular Std Medium', 'Helvetica Neue', 'sans serif'";
export const fontPoppinsSemiBold = "'Poppins SemiBold', 'Arial', 'Helvetica Neue', 'sans serif'";
export const fontPoppinsRegular = "'Poppins Regular', 'Arial', 'Helvetica Neue', 'sans serif'";
export const fontSFProTextRegular = '"San Francisco", -apple-system, BlinkMacSystemFont, sans-serif';
export const fontSize = '16px';
export const tableBorderRadius = '20px';

export const screenBreakWidths = {
  small: '500px',
  large: '1100px',
};

export const screenBreakHeights = {
  large: '800px',
};

export const PageTitleHeader = styled.h1`
  margin-right: 40px;
  font-size: 40px;
  height: 48px;
  margin-bottom: 0;
  font-weight: 700 !important;
  line-height: 120% !important;
  font-style: normal;
`;

export const Header1 = styled.h1`
  font-family: 'Circular Std Medium' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 40px !important;
  margin-bottom: 12px;
  line-height: 160% !important;
`;

export const Header2 = styled.h2`
  font-family: 'Circular Std Medium' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 28px !important;
  margin-bottom: 0;
  line-height: 140% !important;
`;

export const SimproAutomationHeader2 = styled.h2`
  font-family: 'Circular Std Bold' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 32px !important;
  margin-bottom: 0;
  line-height: 140% !important;
`;

export const SimproAutomationHeader3 = styled.h2`
  font-family: 'Circular Std Bold' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 28px !important;
  margin-bottom: 0;
  line-height: 140% !important;
`;

export const Header3 = styled.h3`
  font-family: 'Circular Std Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 20px;
  line-height: 130%;
  color: ${primary1};
`;

export const Header4 = styled.h4`
  font-family: 'Circular Std Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: ${primary1};
`;

export const Header5 = styled.h5`
  font-family: 'Circular Std Book';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  color: ${primary1};
`;

export const ShareReviewHeader = styled.p`
  font-family: ${fontPoppinsSemiBold};
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 140%;
  text-align: center;
  max-width: 310px;
  margin-bottom: 0;
  color: ${gray7};
  @media screen and (min-width: 500px) and (min-height: 800px) {
    font-size: 40px;
    max-width: 500px;
    margin-bottom: 12px;
  }
`;

export const ShareReviewText = styled.p`
  font-family: ${fontPoppinsRegular};
  font-size: 16px;
  margin-top: 0;
  color: ${gray7};
  @media screen and (max-width: 500px) {
    margin-bottom: 0;
  }
  @media screen and (min-width: 1100px) {
    margin-bottom: 24px;
  }
`;

export const pickerStyleBase = css`
  cursor: pointer;
  background-color: ${gray4};
  border-width: 0;
  border-style: solid;
  border-radius: 40px;
  font-family: ${fontFamily1};
  font-size: ${fontSize};
  color: white;
  height: 56px;
  padding-left: 24px;
  padding-right: 30px;
  :hover {
    border-width: 0;
    background-color: ${gray4};
  }
  :focus {
    background-color: ${gray4};
    border-width: 0;
    outline: none;
  }
`;

export const BBLink = styled.a`
  text-decoration: none;
  font-weight: 450;
  color: #338bfb;
  cursor: pointer;
`;

export const YellowBBLink = styled.a`
  text-decoration: none;
  font-weight: 450;
  color: ${simproYellowTextColor};
  :hover {
    color: ${simproYellowHoverLinkColor};
  }
  cursor: pointer;
`;

export const BBRadioButton = styled(FormCheck)({
  '& .form-check-input': { width: 20, height: 20 },
  '& .form-check-input:checked': {
    backgroundColor: primary1,
    borderColor: primary1,
  },
  '& .form-check-label': {
    fontSize: 16,
    fontWeight: 450,
    lineHeight: '160%',
    color: primary1,
    paddingTop: 2,
    marginLeft: 6,
  },
  '& :focus': {
    boxShadow: 'none !important',
    outline: '0px !important',
  },
});

export const BBBlueRadioButton = styled(FormCheck)({
  '& .form-check-input': { width: 20, height: 20 },
  '& .form-check-input:checked': {
    backgroundColor: '#1C4AB9',
    borderColor: '#1C4AB9',
  },
  '& .form-check-label': {
    fontSize: 16,
    fontWeight: 450,
    lineHeight: '160%',
    color: primary1,
    paddingTop: 2,
    marginLeft: 6,
  },
  '& :focus': {
    boxShadow: 'none !important',
    outline: '0px !important',
  },
});

export const checkBoxStyle = css`
  input[type='checkbox'] {
    background-color: ${checkbox};
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 2px;
    outline: none;
    box-shadow: none;
    :not(:checked):not(:indeterminate) {
      background: none;
      border: 1px solid #e1eefe;
      border-radius: 2px;
    }
    :indeterminate {
      background-color: ${checkbox};
    }
  }
  label {
    margin-left: 10px;
    vertical-align: middle;
    font-family: ${fontFamily1};
    font-weight: 450;
  }
`;

const tableStyleBase = css`
  border-collapse: collapse;
  border-spacing: 0 0;
  border-style: hidden;
  box-shadow: -1px 0 0 0 #f1ebe0, 1px 0 0 0 #f1ebe0, 0 -1px 0 0 #f1ebe0;
  margin-bottom: 0;
  th {
    color: #747474;
    font-family: ${fontFamily1};
    font-weight: 450;
    padding: 14px 28px;
    line-height: 25.6px;
    border-bottom: 1px solid #f1ebe0;
    border-right: none;
    border-left: none;
  }
  td {
    vertical-align: middle;
    line-height: 24px;
    overflow: hidden;
    padding: 17px 28px;
    border: none;
    color: #262024;
    font-family: ${fontFamily2};
  }
  tr:first-of-type td {
    padding-top: 24px;
  }
  tr:last-child td {
    padding-bottom: 24px;
  }
`;

export const sortableColumnStyle = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const paginatedTableStyle = css`
  ${tableStyleBase};
  border-radius: ${tableBorderRadius} ${tableBorderRadius} 0 0;
`;

export const noContentTableStyle = css`
  ${tableStyleBase};
  border-radius: ${tableBorderRadius} ${tableBorderRadius} 0 0;
  border-bottom: none;
  height: 100%;
`;

export const emptyTableStyle = css`
  ${tableStyleBase};
  box-shadow: -1px 0 0 0 #f1ebe0, 1px 0 0 0 #f1ebe0, 0 -1px 0 0 #f1ebe0, 0 1px 0 0 #f1ebe0;
  border-radius: ${tableBorderRadius};
`;

export const tableStyle = css`
  ${tableStyleBase};
  border-radius: ${tableBorderRadius};
  box-shadow: -1px 0 0 0 #f1ebe0, 1px 0 0 0 #f1ebe0, 0 -1px 0 0 #f1ebe0, 0 1px 0 0 #f1ebe0;
`;

export const adminTableStyle = css`
  ${tableStyleBase};
  border-radius: ${tableBorderRadius};
  box-shadow: -1px 0 0 0 #f1ebe0, 1px 0 0 0 #f1ebe0, 0 -1px 0 0 #f1ebe0, 0 1px 0 0 #f1ebe0;
  td {
    font-family: ${fontFamily1};
  }
`;

export const lightTableStyle = css`
  ${tableStyleBase};
  border-radius: ${tableBorderRadius} ${tableBorderRadius} 0 0;
  td {
    font-family: ${fontFamily1};
  }
`;

export const selectableTableStyle = css`
  td {
    padding: 4px 0;
  }
  tr > td > div {
    padding: 14px 28px;
    height: 56px;
  }
  tr.selected > td > div {
    background-color: ${blue1};
  }
`;

export const PaginationContainer = styled.div`
  box-shadow: 0.5px 0 0 0.5px #f1ebe0, 0.5px 0 0 0.5px #f1ebe0, -0.5px 0 0 0.5px #f1ebe0;
  border-radius: 0 0 ${tableBorderRadius} ${tableBorderRadius};
  padding: 24.5px 28px;
  font-family: ${fontFamily2};
  margin: 0;
`;

export const NoTopBorderPaginationContainer = styled.div`
  box-shadow: 1px 0 0 0 #f1ebe0, -1px 0 0 0 #f1ebe0, 0 1px 0 0 #f1ebe0;
  border-radius: 0 0 ${tableBorderRadius} ${tableBorderRadius};
  padding: 24.5px 28px;
  font-family: ${fontFamily2};
  margin: 0;
`;

export const Div5Height = styled.div`
  height: 5px;
`;

export const Div10Height = styled.div`
  height: 10px;
`;
export const Div20Height = styled.div`
  height: 20px;
`;
export const Div30Height = styled.div`
  height: 30px;
`;
export const Div40Height = styled.div`
  height: 40px;
`;

export const Div50Height = styled.div`
  height: 50px;
`;

export const Div60Height = styled.div`
  height: 60px;
`;
export const Div20Width = styled.div`
  width: 20px;
`;
export const Div10Width = styled.div`
  width: 10px;
`;
export const Div40Width = styled.div`
  width: 40px;
`;
export const StatusText = styled.div`
  text-align: center;
`;
export const HelpText = styled.p`
  font-size: 14px;
  color: #747474;
`;

export const ExplanationText = styled.p`
  font-size: 16px;
  color: #747474;
  margin-bottom: 0;
`;

export const SetupHelpText = styled.p`
  font-size: 16px;
  color: #747474;
  line-height: 160%;
  margin-bottom: 0;
`;

export const helperMessageStyle = css`
  margin-top: 8px;
  color: #52c082;
  font-size: 12px;
  line-height: 160%;
  padding-left: 16px;
`;

export const warningMessageStyle = css`
  margin-top: 8px;
  color: ${errorColor};
  font-size: 12px;
  line-height: 160%;
  padding-left: 16px;
`;

export const ReviewSiteLogo = styled.img`
  width: 40px;
  height: 40px;
  margin: 12px;
`;

export const AutomationCompanyBadge = styled.div`
  font-family: ${fontFamily2};
  background: #e8fdf1;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 10px;
`;

export const galleryItemStyle = css({
  position: 'relative',
  borderRadius: 20,
  border: '1px solid #e1eefe',
  width: 220,
  height: 230,
  '.action-panel': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    zIndex: 1,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    borderRadius: '0 0 20px 20px',
    height: 0,
    overflow: 'hidden',
    cursor: 'pointer',
    '> div': {
      color: '#1C4AB9',
      fontSize: 14,
    },
  },
  '&:hover .action-panel': {
    height: 45,
    transition: 'height ease-in 0.4s',
  },
});

export const ColorOption = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 8px;
`;
