import React, { createContext, useContext, useState, ReactNode } from 'react';

interface CriticalAPIErrorContextType {
  apiError?: string;
  setAPIError: (error?: string) => void;
}

const CriticalAPIErrorContext = createContext<CriticalAPIErrorContextType | undefined>(undefined);

export const CriticalAPIErrorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [error, setError] = useState<string>();

  return <CriticalAPIErrorContext.Provider value={{ apiError: error, setAPIError: setError }}>{children}</CriticalAPIErrorContext.Provider>;
};

export const useCriticalAPIError = (): CriticalAPIErrorContextType => {
  const context = useContext(CriticalAPIErrorContext);
  if (context === undefined) {
    throw new Error('useCriticalAPIError must be used within a CriticalAPIErrorProvider');
  }
  return context;
};
